'use client'

import { FilterPopup } from '@component/fullPopup/popup'
import { HomeSearchInputNew } from '../input'
import HomeSearchPopup from '../popup'
import { fetchBuy } from '../fetch'

export default function SearchPopup({
  show = false,
  onClose,
  onFilter
}) {
  return (
    // <AppendRoot>
    //   <FullPopup show={show} smCenter maskCloseable={false} contentClassName='!bg-transparent w-full h-fill-screen'>
    //     <div className='w-full h-full bg-white'>
    //       <div className='flex items-center p-16 sm:px-38 sm:pt-28 sm:pb-24'>
    //         {
    //           onBack && <IconButton icon='icon-back' iconClassName='!text-1xl text-primary' onClick={onBack}></IconButton>
    //         }
    //         <span className='flex-1 text-center text-lg font-bold'>{title}</span>
    //         <CloseButton iconClassName='!text-xl' onClick={onClose}></CloseButton>
    //       </div>
    //     </div>
    //   </FullPopup>
    // </AppendRoot>
    <FilterPopup
      show={show}
      title='Search by location'
      onBack={onClose}
      buttonWrapClassName='!p-20'
      confirmButton={
        <div className='button-primary-out w-full' onClick={onClose}>Done</div>
      }
    >
      {
        show && (
          <HomeSearchInputNew
            filterButton
            filterButtonClassName='!p-6 mr-6 border border-primary rounded-sm before:hidden'
            showSearchButton={false}
            onFilter={onFilter}
            autoFocus
          >
            <HomeSearchPopup
              className='!border-t !border-0 mt-16 -mx-16'
              fetcher={fetchBuy}
            ></HomeSearchPopup>
          </HomeSearchInputNew>
        )
      }
    </FilterPopup>
  )
}
