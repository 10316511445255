import { NEW_ZEALAND } from '@config'
import { NEW_HOMES_ALIAS, RESIDENTIAL_ALIAS, RURAL_ALIAS } from '@homeSearch/modules/filter/config/content'
import { houseSearchType } from '@routerConfig'
import { getSuburbLabel } from '@utils/house'
import { map, size, take } from 'lodash-es'

const RECENT_ACTIVITY_KEY = 'or-recent-activity'

export function cacheRecentActivity({ searchType, route, suburbs, slug, filter } = {}) {
  const list = getRecentActivity()
  const title = size(suburbs) ? map(suburbs, getSuburbLabel).join(', ') : NEW_ZEALAND.label
  const text = []

  if (searchType) {
    switch (searchType.route) {
      case houseSearchType.commercialForSale:
      case houseSearchType.commercialForLease:
      case houseSearchType.business:
        text.push('Commercial')
        break
      case houseSearchType.newHomes:
        text.push('New homes')
        break
      default:
        if (![NEW_HOMES_ALIAS.builders, RURAL_ALIAS.rural].includes(searchType.alias)) {
          text.push('Residential')
        }
        break
    }
    switch (searchType.route) {
      case houseSearchType.sold:
        text.push('Sold')
        break
      case houseSearchType.rent:
        text.push('For rent')
        break
      case houseSearchType.newHomes:
        text.push(searchType.alias === NEW_HOMES_ALIAS.apartments ? 'Apartments & Terraces' : 'House & Land')
        break
      case houseSearchType.commercialForSale:
        text.push('For sale')
        break
      case houseSearchType.commercialForLease:
        text.push('For lease')
        break
      case houseSearchType.business:
        text.push('Businesses For Sale')
        break
      case houseSearchType.rural:
        text.push('Rural')
        break
      default:
        // 这里builder和new homes不是同一个路由，所以要分开处理
        if (searchType.alias === NEW_HOMES_ALIAS.builders) {
          text.push('Builder')
        } else if (searchType.alias === RESIDENTIAL_ALIAS.estimate) {
          text.push('Estimate')
        } else {
          text.push('For sale')
        }
        break
    }

    list.unshift({ title, text, route, slug, filter, now: Math.floor(Date.now() / 1000), searchType: searchType.route })
  }

  const cacheData = take(list, 3)
  localStorage.setItem(RECENT_ACTIVITY_KEY, JSON.stringify(cacheData))
  return cacheData
}

export function changeRecentActivity(data, idx) {
  const list = getRecentActivity()
  list[idx] = data
  localStorage.setItem(RECENT_ACTIVITY_KEY, JSON.stringify(list))
}

export function getRecentActivity() {
  const data = localStorage.getItem(RECENT_ACTIVITY_KEY)
  if (data) {
    return JSON.parse(data)
  }
  return []
}